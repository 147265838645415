import React from "react"

import Index from "../../components/index"
import SEO from "../../components/other/seo"
import Container from "../../components/layout/container";
import {Link} from "gatsby";
import {
  Collapsible,
  CollapsibleTitle,
  CollapsibleContent,
} from "../../components/elements/collapsible";
import Video from "../../components/elements/video";
import DisinfectantImage from "../../components/images/disinfectant";
import MicroSure from "../../components/elements/micro-sure";

const DisinfectantPage = () => (
  <Index>
    <SEO title="microSURE® All Purpose Surface Disinfectant" />
    <Container id="product-overview-banner">
      <h1>microSURE® All Purpose Surface Disinfectant</h1>
    </Container>
    <Container id="product-details">
      <div className="left">
        <DisinfectantImage />
      </div>
      <div className="right">
        <h1>
          microSURE® All Purpose<br />
          Surface Disinfectant
        </h1>
        <p><b>EPA Registration Number:</b> #1839‐220</p>
        <hr />
        <p>Unlike traditional cleaning solutions, the <MicroSure /> all purpose surface disinfectant creates an invisible coat to mechanically‐kill 99.99% of viruses and provide lasting protection on any hard, non-porous surface.</p>
        <p>Be prepared to replace all other cleaning solutions and secure your surfaces with the <MicroSure /> surface disinfectant against illness‐causing germs, including Coronavirus. Providing a three to six week protection, the <MicroSure /> all purpose surface disinfectant is safe to use with an EPA toxicity category IV rating.</p>
        <p>With it’s millions of “invisible” nanoscopic crystalline structures, the <MicroSure /> all purpose surface disinfectant disrupts microbial organisms and kills germs on contact.</p>
        <p>
          <Link to="/order" className="button bordered">Order</Link>
        </p>
        <hr />
        <Collapsible>
          <CollapsibleTitle>Additional Benefits</CollapsibleTitle>
          <CollapsibleContent>
            <p>
              Safest EPA toxicity category IV rating for human exposure<br />
              Won’t degrade or discolor surfaces<br />
              Safely dries on any surface indoors or outdoors<br />
              Multipurpose<br />
              Indoor or outdoor use<br />
              5‐year shelf life
            </p>
          </CollapsibleContent>
        </Collapsible>
        <Collapsible>
          <CollapsibleTitle>Available Sizes</CollapsibleTitle>
          <CollapsibleContent>
            <p>
              275 gallon tote<br />
              55 gallon drum<br />
              1 gallon<br />
              1 liter<br />
              500 mL
            </p>
          </CollapsibleContent>
        </Collapsible>
        <Collapsible>
          <CollapsibleTitle>How to Apply</CollapsibleTitle>
          <CollapsibleContent>
            <p>Check our <Link to="/how-to">How-To</Link> section for detailed instructions on how to use the products.</p>
          </CollapsibleContent>
        </Collapsible>
        <Collapsible>
          <CollapsibleTitle>Handling & Storage</CollapsibleTitle>
          <CollapsibleContent>
            <p><b>PESTICIDE DEVICE STORAGE:</b> Store in a dry place inaccessible to children.</p>
            <p><b>CONTAINER HANDLING:</b> Nonrefillable container. Do not reuse or refill this container. Offer for recycling if available or puncture and dispose in a sanitary landfill, incineration, or burning, if allowed by state and local authorities. If burned, stay out of smoke.</p>
            <p><b>CONTAINER DISPOSAL FOR REFILL SPRAY BOTTLE:</b> Do not reuse empty spray bottle except with refill container, otherwise wrap spray bottle and discard in trash. Offer for recycling if available.</p>
            <p><b>PESTICIDE DEVICE DISPOSAL:</b> Pesticide wastes are acutely hazardous. Improper disposal of excess pesticide, spray mixture, or rinsate is a violation of Federal Law. If these wastes cannot be disposed of by use according to label instructions, contact your State Pesticide or Environmental Control Agency, or the Hazardous Waste representative at the nearest EPA Regional Office for guidance</p>
          </CollapsibleContent>
        </Collapsible>
        <Collapsible>
          <CollapsibleTitle>Safety Data Sheet</CollapsibleTitle>
          <CollapsibleContent>
            <p><em>Coming Soon</em></p>
          </CollapsibleContent>
        </Collapsible>
        <p>&nbsp;</p>
      </div>
    </Container>
    <Container id="product-training-videos">
      <div className="left" />
      <div className="right">
        <h2>Training</h2>
        <p>How to apply disinfectant</p>
        <Video title="Video" videoID="HtZNk60ntKw" />
      </div>
    </Container>
  </Index>
)

export default DisinfectantPage
